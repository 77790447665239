
import React from 'react'



const WhatsNew  =()=> {




    return (
        <div className='cryptoex'>
      <section className="pt-100 secureof pb-3">
            <div className="container">
            <h2 className="heading-h2 text-center">What <span className='bluecolor'>We Do?</span></h2>
            <div className="row ">
                <div className='d-flex flex-wrap justify-content-evenly new-flex px-0'>
                    <div class="custom-card">
                            <h3 className='text-left text-md-center'>White Label Rarible Clone Development</h3>
                            <p className='pharagraph text-left text-md-center'>Launch Your NFT Marketplace Effortlessly with Coinsclone's White Label Rarible Clone Software. Our White-Label Software is a ready-made & cost-effective solution that helps you to build a feature-rich NFT Platform like Rarible. Also, our rarible clone script is integrated with top-notch security features ensuring the launch of a secure & scalable NFT Marketplace. Whether you're a startup or an enterprise, our white-label solution allows you to brand and modify the platform to match your vision. Startups like you can enter the booming NFT Marketplace with our powerful, reasonable White label solution of Rarible Marketplace.</p>
                    </div>
                    <div class="custom-card">
                            <h3 className='text-left text-md-center'>Rarible Clone App Development</h3>
                            <p className='pharagraph text-left text-md-center'>Create a user-friendly and eye-catching NFT Marketplace mobile app like Rarible. By choosing our first-class Rarible clone app development service, you can develop an outstanding NFT Marketplace mobile application like Rarible instantly with all cutting-edge features, and security protocols. Our Rarible clone app allows buyers and sellers to deal with non-fungible tokens as well as digital pieces of art. Besides, it is compatible with all the major platforms such as Android and IOS. Also, you can enable additional add-ons on your Rarible clone app as per your necessities. Our premium Rarible clone app works efficiently without any glitches.</p>
                    </div>
                </div>
            </div>
            </div>
      </section>
      </div>
    )
  }


export default WhatsNew