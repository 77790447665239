import React from 'react'


const Categories = () => {


  return (
    <section className="what-make rarible pt-100 mb-0">   
          <div className="container">
            <h3 className="heading-h2 text-center">Our Rarible Clone Can be Designed For <span className='bluecolor'>Various Niches</span></h3>
            <p className="text-center">Our NFT Marketplace developers help design and customize this Rarible clone script for various niches that dominate the digital market.</p>
              <div className='d-flex flex-wrap justify-content-center ind-std'>
                  <p>Art</p>
                  <p>Music</p>
                  <p>Gaming</p>
                  <p>Digital Art</p>
                  <p>Sports</p>
                  <p>Memes</p>
              </div>
          </div>
      </section>
  )
}

export default Categories