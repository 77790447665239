import React from 'react'


class TopFeatures extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    return (
      <section className="topfeaturez pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h4"><span className="bluecolor">Top Features</span> of </span>
                Our Rarible Clone Script</h2>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h3 className="heading-h4">Multi-chain Marketplace</h3>
                <p className="pharagraph">Our Rarible clone software is a multi-chain marketplace that supports transactions such as buying, selling, and creating NFTs on multiple blockchains. Rarible currently supports Ethereum, Flow, and Tezos, and the same way we do support it.
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
              <img className='lazyload'  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/rarible-new/nft-marketplace.png" alt="NFT Marketplace" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order1">
              <img className='lazyload'  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/rarible-new/minting-nfts.png" alt="Minting NFTs" />
            </div>
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-right" >
                <h3 className="heading-h4">List and Sell NFTs</h3>
                <p className="pharagraph">The created or deposited NFTs can be listed in the marketplace that is developed using our Rarible clone. They are for auction or a fixed price for sale with the details of the NFT to sell instantly for a better deal. Once the deal is done, the NFT is sold for the exact price.
                </p>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h3 className="heading-h4">Minting NFTs</h3>
                <p className="pharagraph">A Beginner to the market can create their own NFT with our Rarible Clone by paying the network fee to the respective blockchain which is also known as the gas fee. Minting the NFTs are basic process to kick-start your NFT Trading.</p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6  order1">
              <img className='lazyload'  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/rarible-new/multichain-marketplace.png" alt="Multichain Marketplace" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 right-side order1">
              <img className='lazyload'  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/rarible-new/list-sell-nft.png" alt="List and Sell NFTs" />
            </div>
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-right" >
                <h3 className="heading-h4">Secured wallet connectivity</h3>
                <p className="pharagraph">Connect your external wallet such as Metamask, Torus, Beacon, Blocto, Mobile wallet, Portis, Coinbase, etc which is linked with a highly secured protocol. In addition, separate popups for every transaction that costs each time.
                </p>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex mb-0">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h3 className="heading-h4">Easy Governance</h3>
                <p className="pharagraph">Our Rarible Clone Script offers a seamless governance model, empowering users to participate in decision-making effortlessly. The script includes smart contract-powered governance, automated proposal execution, and a transparent voting mechanism. 
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6  order1">
              <img className='lazyload'  width="551px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/rarible-new/secured-wallet-connectivity.png" alt="Secured wallet connectivity" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TopFeatures