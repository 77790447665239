import React from 'react'



class WhyChoose extends React.Component {


  render() {

    return (
      <section className="whatis pt-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              
              <h2 className="heading-h2"><span className="heading-h3 bluecolor">Why prefer</span> Coinsclone's Rarible Clone Script to Create an NFT Marketplace?</h2>
                <p className="pharagraph head">Coinsclone is the Top-notch <a href='https://www.coinsclone.com/white-label-nft-marketplace/'>White Label NFT Marketplace</a> Development Company in the blockchain industry. Do you want to deploy an NFT Marketplace like Rarible as early as possible? Then partner with us by acquiring our customizable Rarible clone script.
              </p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 left-side" >
              <img  width="476px" height="547px" src="https://coinsclone.mo.cloudinary.net/images/rarible-new/why-choose-coinsclone-for-rarible-clone-script.png" alt="Why Choose our Rarible Clone Script" />
            </div>
            <div className="col-lg-7 col-md-12">
              <p className="pharagraph">We have a team of highly experienced blockchain developers who have rock-solid experience in crafting a superfine Rarible clone software with the latest frameworks. Our NFT Marketplace Clone scripts are,
              </p>
              <ul>
                <li>Built on top of the Ethereum blockchain network at ease.</li>
                <li>Holds all the important features for the long run of the platform.</li>
                <li>Specially designed to encourage all NFT Enthusiasts out there.</li>
                <li>Can be completely customized from top to bottom.</li>
                <li>Supports Add-on modules & trending Plug-ins.</li>
              </ul>
              <p className="pharagraph mb-0">If you want to ensure success for your NFT Marketplace business, then purchasing a premium NFT Marketplace clone script from Coinsclone will be the optimal solution.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose