import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100">

      <Container>
        <div className="text-center">
          <h2 className="heading-h2">Frequently Asked Questions - <span className="bluecolor">Rarible Clone</span></h2>
        </div>

        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">

                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" variant="link" eventKey="0">
                1. What is a Rarible Clone?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">Rarible Clone is a pre-designed software that replicates the features and functionalities of the Community-oriented Rarible NFT Marketplace, that allows trading of NFTs by creators and users.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
                2. How much does your Rarible Clone script cost?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">The approximate cost of our Rarible clone script starts from $15,000, which depends on the customization option you prefer in your NFT Marketplace platform.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                3. Can I customize the Rarible Clone Software you offer?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">Yes, you can modify our premium Rarible clone script as per your preference without any hassle. Our script comes with plenty of customizable options that you have never imagined.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle" variant="link" eventKey="3">
                4. Can an NFT Marketplace mobile app be built using the Rarible clone script?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">You can create a feature-rich NFT Marketplace with your unique business concepts using our Rarible clone script. You can customize them and launch an NFT trading mobile app 100% similar to Rarible within 7 days. Our rarible clone app has attractive UI/UX and upgraded features.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle" variant="link" eventKey="4">
                5. Is your Rarible Clone Script Safe?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">Yes, our Rarible Clone is built with top-tier security measures to ensure a safe and reliable NFT Marketplace. We integrate secure smart contracts, anti-DDoS protection, SSL encryption, and two-factor authentication (2FA) to safeguard user data and transactions.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '5' ? 'panel-wrap active-panel card mb-0' : 'panel-wrap card mb-0'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('5')} className="panel-toggle" variant="link" eventKey="5">
                6. Can I add more features to your Rarible Clone app?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="5">
                <div className="panel-body card-body">Yes! Our Rarible Clone Script is fully customizable, allowing you to add new features and functionalities as per your business needs.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection