import React, { Component } from 'react'

class CoreFeatures extends Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">

        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

            <h2 className="heading-h2"><span className="heading-h4"><span className="bluecolor">Core Features</span> of </span>
              Our Rarible Clone Script</h2>
          </div>
          <div className="row">

            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role='presentation'>
                <li className="current"  role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab4')} >
                  Revenue Management
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab5')} >
                  Customizable Gallery Mode
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab6')} >
                  Fix your Royalties
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab7')} >
                  Work with Community
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab8')} >
                  Multi-lingual Support
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab9')} >
                  Bug Bounty Program
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab10')} >
                  Get Verified, Demystified!
                </li>
              </ul>

              <div className="tab-content">
                <div id="tab4" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Revenue Management
                        </h3>
                        <p className="pharagraph">The Rarible clone admin console simplifies management and configures revenue for transactions. This includes NFT minting, listing for sale, successful buy/sell transactions and withdrawing NFTs to external wallets with a flexible fee structure.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img className='lazyload' width="507px" height="333px" src="https://coinsclone.mo.cloudinary.net/images/rarible-new/revenue-management.png" alt="Revenue Management" />
                    </div>
                  </div>
                </div>
                <div id="tab5" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Customizable Gallery Mode
                        </h3>
                        <p className="pharagraph">The NFT Marketplace gallery provides a unique gallery mode user Interface and high-resolution image viewer and video player integration to select the collectibles appropriately.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img className='lazyload mw380' width="414px" height="308px" src="https://coinsclone.mo.cloudinary.net/images/rarible-new/customizable-gallery-mode.png" alt="Customizable Gallery Mode" />
                    </div>
                  </div>
                </div>
                <div id="tab6" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Fix your Royalties
                        </h3>
                        <p className="pharagraph">The Rarible clone software allows the creator of an NFT to set their royalties which will be incurred automatically whenever it is sold to another buyer. The standard royalty is between 5 - 10% of the selling price and it can also be customizable according to the requirement in the smart contract.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img className='lazyload' width="506px" height="307px" src="https://coinsclone.mo.cloudinary.net/images/rarible-new/fix-your-royalties.png" alt="Fix your Royalties" />
                    </div>
                  </div>
                </div>
                <div id="tab7" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Work with Community
                        </h3>
                        <p className="pharagraph">Our Rarible clone script enables the admin to establish a community with features like editable and posting blogs. Also, gathering feedback and suggestions from the users, a chat box, and a roadmap to engage with the users.</p>
                         </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img className='lazyload mw315' width="430px" height="298px" src="https://coinsclone.mo.cloudinary.net/images/rarible-new/work-with-community.png" alt="Work with Community" />
                    </div>
                  </div>
                </div>
                <div id="tab8" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Multi-lingual Support
                        </h3>
                        <p className="pharagraph">Our Rarible NFT Clone offers a Multilingual option for the users to access the platform globally without having any kind of barrier with language which is a mandatory feature and our scalable script provides the same with the required language integration in the platform.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img className='lazyload' width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/rarible/multilingual-support.png" alt="Multi-lingual Support" />
                    </div>
                  </div>
                </div>
                <div id="tab9" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Bug Bounty Program
                        </h3>
                        <p className="pharagraph">Report a bug or vulnerability with the proper details and links or attachments in the platform and if it exists/valid get rewarded! This helps in making the platform more robust as well as to identify weakness in the system and security gaps before bad actors do.</p>
                         </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img className='lazyload mw380' width="428px" height="312px" src="https://coinsclone.mo.cloudinary.net/images/rarible-new/bug-bounty-program.png" alt="Bug Bounty Program" />
                    </div>
                  </div>
                </div>
                <div id="tab10" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Get Verified, Demystified!
                        </h3>
                        <p className="pharagraph">Our Rarible readymade clone script offers a verification module that can verify the user's identity and social media profiles to ensure security. The Verified profiles get a lot of visibility in the marketplace, leaderboards as well as search results whereas non-verified profiles will not.</p>
                         </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img className='lazyload' width="415px" height="309px" src="https://coinsclone.mo.cloudinary.net/images/rarible-new/get-verified-demystified.png" alt="Get Verified, Demystified!" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CoreFeatures