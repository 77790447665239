import React from "react"

class RevenueGen extends React.Component {

  render() {
    return (
      <div className="pt-100">
      <section className="devmethod mb-0 hdonly">
        <div className="container"> 
          <h3 className="heading-h2 text-center">
            <span className="bluecolor">Revenue Streams</span> Of Rarible Clone Script</h3>
          <p className="text-center">
          Our Rarible clone script enables startups and enterprises to launch their own NFT marketplace, unlocking multiple revenue streams.
          </p>
          <div className="d-lg-flex">
            <div className="square">
              <h4 className="head3">
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/rarible/transaction_fees.png"
                  alt="Transaction fees"
                />
                <span>Transaction fees</span>
              </h4>
              <p>Generate consistent earning through frequent NFT trading.</p>
            </div>
            <div className="square">
              <h4 className="head3">
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/rarible/listing_fees.png"
                  alt="Listing fees"
                />
                <span>Listing fees</span>
              </h4>
              <p>Additional income source by listing various NFTs.</p>
            </div>
            <div className="square">
              <h4 className="head3">
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/rarible/minting_fees.png"
                  alt="Minting fees"
                />
                <span>Minting fees</span>
              </h4>
              <p>Revenue as users create new NFTs on the platform.</p>
            </div>
          </div>
          <div className="d-lg-flex">
          <div className="square">
              <h4 className="head3">
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/rarible/subscription_fees.png"
                  alt="Subscription fees"
                />
                <span>Subscription Fees</span>
              </h4>
              <p>By offering premium features for artists and collectors.</p>
            </div>
            <div className="square">
              <h4 className="head3">
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/rarible/additional_fees.png"
                  alt="Additional fees"
                />
                <span>Additional Fees</span>
              </h4>
              <p>Advertisements and featured listings help maximize profits.</p>
            </div>
            <div className="square">
              <h4 className="head3">
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/rarible/royalty_fees.png"
                  alt="Auction bidding fee"
                />
                <span>Royalty fees</span>
              </h4>
              <p>Marketplace owners earn commissions on secondary sales.</p>
            </div>
          </div>
        </div>
      </section>
      </div>
    )
  }
}

export default RevenueGen
