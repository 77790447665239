import React from 'react'



const VariousBlock =()=> {

    return (
      <section className="secureof lucrative centeralign pt-100 mb-0">
        <div className="container">
        <h3 className="heading-h2 text-center">Our Rarible Clone Script supports <span className="bluecolor">Multiple Blockchain</span> Networks</h3>
          <p className="text-center">Our Rarible-like NFT Marketplace supports integration with the most popular blockchain networks. Our team of talented developers has designed the software to be compatible with multiple blockchains in the crypto market.</p>
          <div className="factor">
            <div className="revenue">
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/ethereum.png" alt="Ethereum" />
              <span>Ethereum</span></p>
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/binance.png" alt="Binance" /><span>Binance Smart Chain</span></p>
            </div>
            <div className="revenue">
            <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/solana.png" alt="Solana" /><span>Solana</span></p>
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/polygon.png" alt="Polygon" /><span>Polygon</span></p>
            </div>
            <div className="revenue">
            <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/rarible/tezos.png" alt="Tezos" /><span>Tezos</span></p>
            <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/rarible/immutable_x.png" alt="Immutable X" /><span>Immutable X</span></p>
            </div>
          </div>
        </div>
      </section>
    )
}

export default VariousBlock